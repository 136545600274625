.Canvas {
  position: fixed;
  width: 100%;
  height: 100%;
}
.Canvas img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  background: #000;
  color: #fff;
  font-family: Helvetica Neue, Helvetica, Arial;
  font-size: 62.5%;
}
html a {
  color: #fff;
}
html .Header {
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: auto;
  color: #fff;
  z-index: 3;
  font-size: 1.1rem;
}
html .Header li {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
html .layout {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}
html .Preload {
  width: 100%;
  height: 100%;
  position: absolute;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
html .Preload .progress {
  font-size: 3vw;
  margin: auto;
}
html .Progress {
  width: 100%;
  height: 100%;
  position: absolute;
}
html .Progress .top-progress {
  height: 1.6rem;
  left: 2rem;
  overflow: hidden;
  top: 2rem;
  width: 7rem;
  position: absolute;
}
html .Progress .top-progress .top-progress-bar {
  background: #fff;
  height: 100%;
  position: relative;
  width: 100%;
}
html .Progress .center-progress {
  top: 30%;
  left: 2rem;
  height: 11rem;
  position: absolute;
  width: 60%;
  overflow: hidden;
}
html .Progress .center-progress .center-progress-bar {
  height: 100%;
  background: #fff;
  width: 100%;
  position: relative;
}
html .Progress .right-progress {
  top: 30%;
  right: 2rem;
  height: 0.2rem;
  position: absolute;
  width: 5rem;
  overflow: hidden;
}
html .Progress .right-progress .right-progress-bar {
  height: 100%;
  background: #fff;
  width: 100%;
  position: relative;
}
html .Progress .bottom-progress {
  height: 1.6rem;
  left: 2rem;
  overflow: hidden;
  bottom: 2rem;
  width: 10rem;
  position: absolute;
  font-size: 1.1rem;
}
html .Studio {
  position: absolute;
  width: 100%;
  height: 100%;
}
html .Home {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
}
html .Work {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
}
html .NotFound {
  position: absolute;
  width: 100%;
  height: 100%;
}
